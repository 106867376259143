import Enum from './enum'

/**
 * 部門
 */
class Division extends Enum {
    static APPOINTMENT_TEAM = 1; // アポチーム
    static FIELD_SALES = 2; // 営業部
    static SALES_PERSON = 3; // 営業部以外営業マン
    static NONE = 9; // 未設定


    static values() {
        return {
            [this.APPOINTMENT_TEAM]: 'アポチーム',
            [this.FIELD_SALES]: '営業部',
            [this.SALES_PERSON]: '営業部以外営業マン',
            [this.NONE]: '未設定',
        }
    }
}

export default Division;
